import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "../components/container"
import { Button, Link, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Wrap, WrapItem } from "@chakra-ui/react"
import { ChatIcon, LinkIcon } from '@chakra-ui/icons'
import { Helmet } from "react-helmet"

// markup
const IndexPage = () => {
    const image = useStaticQuery(graphql`
        query {
            file(relativePath: {eq: "logo.svg"}) {
                publicURL
            }
        }
    `)

    console.log(image.file.publicURL);


    return (
        <>
            <Helmet>
                <title>Atelier of Arlier</title>
                <html lang="ja" />
                <meta charSet="utf-8" />
                <meta property="og:title" content="Atelier of Arlier Official Website" />
                <meta property="og:description" content="VR洋服ブランド Atelier of Arlierの公式サイトです。" />
                <meta property="og:url" content="https://atelier-of-arlier.com" />
                <meta property="og:image" content="https://i.gyazo.com/47c5a0c3f3cbe23a594bbcd285b546fb.png" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Atelier of Arlier" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <header className="mb-28 relative w-full">
                <StaticImage className="h-screen w-full object-cover brightness-50" src="https://i.gyazo.com/dc8085596b36bbdcfe9884afde5f40b6.jpg" alt="" />
                <div className=" absolute top-0 left-0 h-full w-full flex items-center">
                    <div className=" text-white px-6 md:px-24 xl:px-56" style={{ "fontFamily": 'Oleo Script Swash Caps, cursive' }}>
                        <h1 className="text-5xl lg:text-7xl">Atelier of Arlier</h1>
                        <div className="mt-8 text-lg lg:text-xl" style={{ "fontFamily": "Murecho, sans-serif" }}>
                            <p>
                                「より良いものをもっと多くの人に届ける」をコンセプトに<br />
                                お客様と共に進化し続けるVR洋服ブランドです。
                            </p>
                        </div>
                    </div>
                </div>
            </header>
            <section className=" mb-28 px-4 flex flex-col lg:flex-row items-center justify-center" style={{ "fontFamily": "Murecho, sans-serif" }}>
                <div className="w-96 max-w-full">
                    <img src={image.file.publicURL} alt="" />
                </div>
                <div className="ml-0 mt-10 lg:ml-8 lg:mt-5">
                    <h1 className="text-2xl lg:text-3xl font-bold">人が、創ってます。</h1>
                    <p className="mt-8 text-xl">
                        Atelier of Arlierは、人と人との繋がりを大切します。<br />
                        お客様とクリエイターの目線に立って常に考え、困ったことがあれば一緒に解決します。
                    </p>
                    <p className="mt-4 text-xl">
                        なぜなら私たちは、人と人との繋がりこそが、大きな幸せを生むと考えるからです。
                    </p>
                </div>
            </section>
            <Container>
                <h1 className="text-3xl lg:text-4xl font-bold" style={{ "fontFamily": "Murecho, sans-serif" }}>作品一覧</h1>
                <div className="mb-28 py-10 grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-8 md:gap-4" style={{ "fontFamily": 'Oleo Script Swash Caps, cursive' }}>
                    <div>
                        <a href="https://atelierarlier.booth.pm/items/3950974" target={"_blank"}>
                            <StaticImage src="https://i.gyazo.com/f7ac0e6bab1f9fc314e3398dc30e16c7.jpg" className="drop-shadow-lg transition duration-300 hover:drop-shadow-2xl" alt="" />
                            <p className="text-4xl mt-4 ml-1">Sheliak</p>
                        </a>
                    </div>
                    <Popover>
                        <PopoverTrigger>
                            <div>
                                <StaticImage src="https://i.gyazo.com/703a967afe44189bbb2b0f44431253a2.png" className="drop-shadow-lg transition duration-300 hover:drop-shadow-2xl" alt="" />
                                <p className="text-4xl mt-4 ml-1">Aquali</p>
                            </div>
                        </PopoverTrigger>
                        <PopoverContent style={{ "fontFamily": "Murecho, sans-serif" }}>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>対応アバター</PopoverHeader>
                            <PopoverBody>
                                <Wrap>
                                    <WrapItem>
                                        <Button colorScheme='blue' as="a" href="https://atelierarlier.booth.pm/items/3870281" target={"_blank"}>ラスク</Button>
                                    </WrapItem>
                                    <WrapItem>
                                        <Button colorScheme='blue' as="a" href="https://atelierarlier.booth.pm/items/3873788" target={"_blank"}>薄荷</Button>
                                    </WrapItem>
                                    <WrapItem>
                                        <Button colorScheme='blue' as="a" href="https://atelierarlier.booth.pm/items/3819997" target={"_blank"}>Lua</Button>
                                    </WrapItem>
                                </Wrap>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                    <Popover>
                        <PopoverTrigger>
                            <div>
                                <StaticImage src="https://i.gyazo.com/c632f5dff9d68abda298bc75159ee8a0.png" className="drop-shadow-lg transition duration-300 hover:drop-shadow-2xl" alt="" />
                                <p className="text-4xl mt-4 ml-1">Shining Star Dress</p>
                            </div>
                        </PopoverTrigger>
                        <PopoverContent style={{ "fontFamily": "Murecho, sans-serif" }}>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>対応アバター</PopoverHeader>
                            <PopoverBody>
                                <Wrap>
                                    <WrapItem>
                                        <Button colorScheme='blue' as="a" href="https://atelierarlier.booth.pm/items/3845144" target={"_blank"}>ラスク</Button>
                                    </WrapItem>
                                    <WrapItem>
                                        <Button colorScheme='blue' as="a" href="https://atelierarlier.booth.pm/items/3774467" target={"_blank"}>薄荷</Button>
                                    </WrapItem>
                                    <WrapItem>
                                        <Button colorScheme='blue' as="a" href="https://atelierarlier.booth.pm/items/3363572" target={"_blank"}>Lua</Button>
                                    </WrapItem>
                                </Wrap>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                    <Popover>
                        <PopoverTrigger>
                            <div>
                                <StaticImage src="https://i.gyazo.com/567faf6a234e6a09a3c2e6e9fb921d6e.png" className="drop-shadow-lg transition duration-300 hover:drop-shadow-2xl" alt="" />
                                <p className="text-4xl mt-4 ml-1">Triad Shooting Star</p>
                            </div>
                        </PopoverTrigger>
                        <PopoverContent style={{ "fontFamily": "Murecho, sans-serif" }}>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>対応アバター</PopoverHeader>
                            <PopoverBody>
                                <Wrap>
                                    <WrapItem>
                                        <Button colorScheme='blue' as="a" href="https://atelierarlier.booth.pm/items/3591186" target={"_blank"}>ラスク</Button>
                                    </WrapItem>
                                    <WrapItem>
                                        <Button colorScheme='blue' as="a" href="https://atelierarlier.booth.pm/items/3501670" target={"_blank"}>Lua</Button>
                                    </WrapItem>
                                </Wrap>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </div>
                <h1 className="text-3xl lg:text-4xl font-bold" style={{ "fontFamily": "Murecho, sans-serif" }}>クリエイター</h1>
            </Container>
            <section className="mt-10 mb-28 px-4 flex flex-col lg:flex-row items-center justify-center" style={{ "fontFamily": "Murecho, sans-serif" }}>
                <div className=" w-60 max-w-full">
                    <StaticImage className="rounded-full" src="https://i.gyazo.com/b5bb0e9783e42cd28b15fe3319ca42c0.jpg" alt="" />
                </div>
                <div className="ml-0 mt-10 lg:ml-8 lg:mt-0">
                    <h1 className="text-2xl lg:text-4xl font-bold">AceHunting</h1>
                    <p className="mt-8 text-xl">
                        Atelier of ArlierのオーナーでBlenderとUnityを駆使して服を制作しています。<br />
                        山口と岡山を中心にスポーツカーによる町おこしのボランティア活動も行っています。
                    </p>
                    <div className="mt-5">
                        <Wrap>
                            <WrapItem>
                                <Button colorScheme='twitter' leftIcon={<ChatIcon />} as="a" href="https://twitter.com/Mustatiikeri208" target={"_blank"}>Twitter</Button>
                            </WrapItem>
                            <WrapItem>
                                <Button colorScheme='pink' leftIcon={<LinkIcon />} as="a" href="https://atelierarlier.booth.pm/" target={"_blank"}>Booth</Button>
                            </WrapItem>
                        </Wrap>
                    </div>
                </div>
            </section>
            <Container>
                <div className="mb-28 py-16 px-4 md:px-0 shadow-2xl shadow-cyan-500/50 text-center border border-cyan-500 rounded" style={{ "fontFamily": "Murecho, sans-serif" }}>
                    <h1 className="text-base md:text-2xl lg:text-3xl font-bold">＼オーダーメイドを受け付けています！／</h1>
                    <p className="mt-8 mb-10 text-lg md:text-xl">あなたのための専用衣装を制作します。<br />
                    価格やご相談はTwitterのDMまでお願いします！</p>
                    <Button colorScheme='twitter' leftIcon={<ChatIcon />} as="a" href="https://twitter.com/Mustatiikeri208" target={"_blank"}>DMで相談してみる！</Button>
                </div>
            </Container>
            <Container>
                <div className="py-8" style={{ "fontFamily": "Murecho, sans-serif" }}>
                    <p>&copy; Atelier of Arlier 2022 ｜ Designed by <Link color='teal.500' href="https://twitter.com/minatoo86" target={"_blank"}>Minato Minami</Link></p>
                </div>
            </Container>
        </>
    )
}

export default IndexPage
