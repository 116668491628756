import * as React from "react"
import { Link as GatsbyLink } from "gatsby"

const Container = ({ children }: any) => {
    return(
        <div className=" max-w-7xl mx-auto px-4 md:px-8">
            { children }
        </div>
    )
}

export default Container